import { createContext, useContext, useState } from "react";
import mixpanel from "mixpanel-browser";
import axios from "axios";

const INITIAL_STATE = {
  loggedIn: false,
  state: "uninitialized",
};

//Context object to hold our auth data
const authContext = createContext(false);

//Helper function to encode username and password for API
const encodeLoginInfo = (username, password) => {
  return {
    type: "base64",
    value: btoa(username + ":" + password),
    namespace: `${process.env.API_URL}/customers`,
  };
};

//remove some complexity from the way profile info is returned from the api
const mapResponseToUserInfo = (response, token, encodeToken = false) => {
  return {
    loggedIn: true,
    customAttributes: response.customAttributes,
    name: `${response.humanName.firstName} ${response.humanName.lastName}`,
    email: response.email,
    unicityId: response.unicity,
    hasGovernmentId: !!response.flags?.hasGovernmentId,
    token: encodeToken ? atob(token) : token,
    state: response.type === "Associate" ? "complete" : "unauthorized",
    profilePictureUrl: response.profilePicture.sizes[0]?.media,
    countryCode: response.mainAddress.country,
    joinDate: response.joinDate,
    type: response.type,
    sponsor: response.sponsor,
    enroller: response.enroller,
    homePhone: response.homePhone,
    mobilePhone: response.mobilePhone,
    href: response.href,
    status: response.status,
  };
};

//Return the context object required by consumers of auth context
const useAuth = () => {
  return useContext(authContext);
};

//AuthProvider element that wraps the application to make auth context consistently available
const AuthProvider = ({ children }) => {
  //check local storage for current session
  const existingSessionData = localStorage.getItem("user");

  //Holds user info
  const [userInfo, setUserInfo] = useState(
    existingSessionData ? JSON.parse(existingSessionData) : INITIAL_STATE
  );

  // Error code for login
  const [errorCode, setErrorCode] = useState(null);

  //Make an API call to login the user
  const getLoginToken = async (username, password) => {
    return axios({
      method: "post",
      url: `${process.env.API_URL}/loginTokens`,
      data: encodeLoginInfo(username, password),
      headers: {
        "x-application": `office.web`
      }
    });
  };

  //API call to get the user's highest rank
  const getMetricsProfileHistory = async (token) => {
    return axios({
      method: "get",
      url: `${process.env.API_URL}/customers/me/metricsProfileHistory`,
      headers: {
        Authorization: `Bearer ${token}`,
        "x-application": `office.web`
      },
    });
  };

  const setAndStoreUserInfo = (userInfo) => {
    setUserInfo(userInfo);
    localStorage.setItem("user", JSON.stringify(userInfo));
  };

  const handleError = (err) => {
    console.error(err);
    setUserInfo({ state: "error" });
  };

  const fetchUserInfo = async (token, encodeToken = false) => {
    try {
      const { data: response } = await axios({
        method: "get",
        url: `${process.env.REACT_APP_OFFICE_BE_PH_URL}/v1/user/me`,
        headers: {
          Authorization: `Bearer ${encodeToken ? atob(token) : token}`,
          "x-application": `office.web`
        },
      });
      if (response.data.type !== "Associate") {
        mixpanel.track("login_attempt", {
          login_status: "failed",
          login_status_details: "not_a_distriutor"
        })
      } else {
        mixpanel.track("login_attempt", {
          login_status: "successful"
        });
      }

      const newUserInfo = mapResponseToUserInfo(response.data, token, encodeToken);
      const metricsProfileHistoryPromise = getMetricsProfileHistory(
        encodeToken ? atob(token) : token
      ).catch(err => {
        console.error("Error fetching metrics profile history:", err);
        return { data: { aggregate: { cumulativeMetricsProfile: {} } } };
      });

      const statsPromise = axios({
        method: "get",
        url: `${process.env.REACT_APP_OFFICE_API}/${newUserInfo.unicityId}/stats`,
        headers: {
          Authorization: `Bearer ${encodeToken ? atob(token) : token}`,
          "x-application": `office.web`
        },
      }).catch(err => {
        console.error("Error fetching stats:", err);
        return { data: {} };
      });

      const metricsProfileHistoryResponse = await metricsProfileHistoryPromise;
      const statsResponse = await statsPromise;

      const highestRank = metricsProfileHistoryResponse.data.aggregate.cumulativeMetricsProfile.highestRankShort;
      const { totalOv, partnersCount, membersCount } = statsResponse.data;

      const finalizedUserInfo = {
        ...newUserInfo,
        highestRank,
        totalOv,
        partnersCount,
        membersCount
      };

      return finalizedUserInfo;
    } catch (err) {
      handleError(err);
      throw err;
    }
  };


  const silentLogin = (token, isPwa = false) => {
    setUserInfo({ loggedIn: false, state: "wait" });

    return fetchUserInfo(token, true)
      .then((userInfo) => {
        return { ...userInfo, isPwa };
      })
      .then(setAndStoreUserInfo)
      .catch(handleError);
  };

  const login = (username, password, isPwa = false) => {
    setUserInfo({ loggedIn: false, state: "wait" });
    return getLoginToken(username, password)
      .then((response) => fetchUserInfo(response.data.token))
      .then((userInfo) => {
        return { ...userInfo, isPwa };
      })
      .then((userInfo) => {
        setAndStoreUserInfo(userInfo);
        return true;
      })
      .catch((error) => {
        setErrorCode(error.response?.status);
        if (error.response?.status === 429) {
          mixpanel.track("login_attempt", {
            login_status: "failed",
            login_status_details: "too_many_requests"
          });
        } else if (error.response?.status === 401) {
          mixpanel.track("login_attempt", {
            login_status: "failed",
            login_status_details: "invalid_credentials"
          });
        }

        handleError(error);
        throw error;
      });
  };

  const reauthenticate = (username, password) => {
    return refreshUserInfo(userInfo.token)
      .then((refreshedUserInfo) => {
        return getLoginToken(username, password)
          .then((res) => {
            if (res?.data?.customer?.href !== refreshedUserInfo.href) {
              userInfo.state = "error";
              return false;
            }
            return res.status === 201 ? true : false;
          })
      })
  }

  const refreshUserInfo = (token) => {
    return fetchUserInfo(token)
      .then((refreshedUserInfo) => {
        setAndStoreUserInfo(refreshedUserInfo);
        return refreshedUserInfo;
      })
      .catch(handleError);
  };

  //Remove current auth context
  const logout = () => {
    setUserInfo(INITIAL_STATE);

    mixpanel.reset();

    localStorage.removeItem("user");
  };

  //Value of auth context
  const auth = {
    silentLogin,
    login,
    logout,
    userInfo,
    setUserInfo,
    refreshUserInfo,
    reauthenticate,
    errorCode
  };

  //Auth provider element that wraps the application (see index.js)
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export { useAuth, AuthProvider };
